





























import {
  defineComponent,
  computed,
  onMounted,
  useContext,
  onBeforeUnmount
} from '@nuxtjs/composition-api';
import { useListen, useUnListen } from '@wemade-vsf/composables';
import { useQuoteCartState, useQuoteCart } from '@wemade-vsf/quote-cart';
import { SfButton, SfBadge } from '@storefront-ui/vue';

export default defineComponent({
  name: 'QuoteCartIcon',
  props: {
    icon: {
      type: String,
      default: 'comment-dollar'
    },
    iconSize: {
      type: String,
      default: '1.6875rem'
    }
  },
  components: {
    SfButton,
    SfBadge,
    QuoteRequestModal: () => import(/* webpack prefetch: true */ 'components/quote-cart/QuoteRequestModal.vue')
  },
  setup () {
    const { app } = useContext();
    const { toggleQuoteCartSidebar, isQuoteRequestModalOpen, closeQuoteRequestModal } = useQuoteCartState();
    const { loadTotalQty, quoteCart, mergeQuotes } = useQuoteCart();

    const cartTotalItems = computed(() => quoteCart.value?.total_quantity ?? 0);

    function possiblyMergeQuotes(retries = 0) {
      if (app.$vsf.$magento.config.state.getCustomerToken()) {
        mergeQuotes();
      } else {
        if (retries < 5) {
          setTimeout(() => possiblyMergeQuotes(retries + 1), 500);
        }
      }
    }

    onMounted(async () => {
      await loadTotalQty();
      useListen('user:login', possiblyMergeQuotes)
    });

    onBeforeUnmount(() => {
      useUnListen('user:login', possiblyMergeQuotes);
    });

    return {
      toggleQuoteCartSidebar,
      quoteCartTotalItems: cartTotalItems,
      isQuoteRequestModalOpen,
      closeQuoteRequestModal
    }
  }
});
